import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    name: 'Layout',
    component: () => import('@/layout/index.vue'),
    redirect: '/index',
    children: [
      {
        path: '/index',
        name: 'index',
        component: () => import('@/views/Index/index.vue')
      },
      {
        path: '/product',
        name: 'product',
        component: () => import('@/views/Product/index.vue')
      },
      {
        path: '/about-us',
        name: 'about-us',
        component: () => import('@/views/AboutUS/index.vue')
      },
      {
        path: '/cases',
        name: 'cases',
        component: () => import('@/views/Cases/index.vue')
      },
      {
        path: '/partners',
        name: 'partners',
        component: () => import('@/views/Partners/index.vue')
      },
    ]
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior: () => ({
    y: 0
  })
})

export default router
